// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import ContactCard from "../components/contactcard"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

function Contact(props: PageProps): JSX.Element {
  return (
    <Layout>
      <SEO title="Contact" />
      <ContactCard />
    </Layout>
  )
}

export default Contact
