/**
 * React component that renders a personal contact card with info and an image 
 * This is a horizontal card based on Flowbite https://flowbite.com/docs/components/card/ 
 * 
 * Darkmode Enabled
 * */

import { Link } from "gatsby"
import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

function ContactCard() {
    return (
        <div className="flex flex-col-reverse items-center justify-between bg-white md:flex-row dark:border-gray-700 dark:bg-gray-800">

            {/* left hand column */}
            <div className="text-gray-600 flex flex-col p-4 leading-normal">
                {/* first row */}
                <div className="row flex grow justify-left mr-6 my-1">
                    <span className="label w-20 ">
                        <label>Github</label>
                    </span>
                    <div>
                        <Link
                            to={`https://github.com/vasusen`}
                            className="grow"
                        >
                            https://github.com/vasusen
                        </Link>
                    </div>
                </div>
                {/* second row */}
                <div className="row flex grow justify-left mr-6 my-1">
                    <span className="label w-20">
                        <label className="">Twitter</label>
                    </span>
                    <div>
                        <Link
                            to={`https://twitter.com/vasusen`}
                            className="grow"
                        >
                            https://twitter.com/vasusen
                        </Link>
                    </div>
                </div>
                {/* third row */}
                <div className="row flex grow justify-left mr-6 my-1">
                    <span className="label w-20 ">
                        <label>LinkedIn</label>
                    </span>
                    <div>
                        <Link
                            to={`https://www.linkedin.com/in/vasusen/`}
                            className="grow"
                        >
                            https://www.linkedin.com/in/vasusen/
                        </Link>
                    </div>
                </div>
                {/* fourth row */}
                <div className="row flex grow justify-left mr-6 my-1">
                    <span className="label w-20 ">
                        <label>Location</label>
                    </span>
                    <div>
                        <Link
                            to={`https://www.google.com/maps/place/Redwood+City,+California`}
                            className="grow"
                        >
                            Redwood City, California
                        </Link>
                    </div>
                </div>
            </div>

            {/* right hand column image */}
            {/*  object-cover w-full h-96 rounded-lg md:h-auto md:w-48 */}
            <div className="flex grow">
                <StaticImage
                    className="object-cover w-48 h-auto rounded-lg"
                    src="../images/vasusen.jpg"
                    alt="vasusen profile picture" />
            </div>
        </div>
    )
}

export default ContactCard